import barba from '@barba/core';
import barbaCss from '@barba/css';

"use strict";

barba.use(barbaCss);
 
barba.init({
  transitions: [{
    name: 'fade',
    leave(data) {},
    enter(){}
  }]
});

barba.hooks.beforeEnter((data) => {
  scrollTo(0,0);
});

const html = document.documentElement;
html.classList.remove('no-js');

// Preload
window.addEventListener('load', () => {
  const body = document.body;
  const loader = document.getElementById('loader');
  
  const speed	= 500;
  setTimeout(() => load(), speed);
  setTimeout(() => {
    document.querySelector('body').classList.add('loaded');
  }, speed+2000);
  
  if (body.classList.contains('home')) {
    if (scrollpos >= headerHeight) { 
      document.querySelector('.header').classList.add('loaded');
      addClassOnScroll();
    } else {
      setTimeout(() => {
        document.querySelector('.header').classList.add('loaded');
      }, speed+4500);
    }
    
  } else {
    document.querySelector('.header').classList.add('loaded');
  }
  
  addHeaderBlur();
    
});

const load = () => {
  
  // const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
  const preloader = document.getElementById('preloader');
  
  // if (!isMobile) {
    setTimeout(function() {
      preloader.classList.add('preloaded');
    }, 800);
    setTimeout(function() {
      preloader.remove();
    }, 2000);

  // } else {
  //   preloader.remove();
  // }
}

// Up balloon
const up = document.getElementById('up');
up.addEventListener('click', () => {
  scrollTo(0,0);
});

// Header - Blur In
let scrollpos = window.scrollY;
const header = document.querySelector('header');
const headerHeight = header.offsetHeight;

const addClassOnScroll = () => header.classList.add('blur-in');
const removeClassOnScroll = () => header.classList.remove('blur-in');

const addHeaderBlur = () => {
  if (scrollpos >= headerHeight) {
    addClassOnScroll()
  } else { 
    removeClassOnScroll()
  }
}

window.addEventListener('scroll', () => {
  scrollpos = window.scrollY;
  addHeaderBlur();
})
